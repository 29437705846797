import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Container from './Container';
import SectionHeader from './SectionHeader';

const services = [
  {
    id: 'digital-asset-creation',
    title: 'Digital Asset Creation',
    description: 'From web applications to custom software, we develop tools that generate value and drive business efficiency.',
  },
  {
    id: 'business-consulting',
    title: 'Business Consulting',
    description: 'Strategic guidance tailored to align technology with your core business objectives.',
  },
  {
    id: 'project-management',
    title: 'Project Management',
    description: 'Comprehensive oversight of technical projects, ensuring on-time delivery and measurable success.',
  },
];

const Services = () => {
  return (
    <React.Fragment>
      <Header />
      <Container>
        <SectionHeader>Our Services</SectionHeader>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {services.map((service) => (
            <Link
              key={service.id}
              to={`/services/${service.id}`}
              className="bg-white p-6 rounded-lg shadow-lg hover:bg-primary hover:text-white transition focus:outline-none focus:bg-primary focus:text-white"
              aria-label={`Learn more about ${service.title}`}
            >
              <h2 className="text-2xl font-semibold mb-2">{service.title}</h2>
              <p>{service.description}</p>
            </Link>
          ))}
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Services;
