import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Container from './Container';
import SectionHeader from './SectionHeader';

function Home() {
  return (
    <>
      <Header />
      <Container>
        <SectionHeader>Our Services</SectionHeader>
        <div className="bg-gradient-to-r from-gray-300 to-gray-200 p-12 text-center rounded-lg shadow-xl">
          <h1 className="text-6xl font-extrabold mb-6">Welcome to Zialectics</h1>
          <p className="text-lg text-gray-700">
            Your trusted partner for technology powering business.
          </p>
          <Link to="/contact">
            <button className="ctaButton">Contact Us</button>
          </Link>
        </div>
      </Container>
    </>
  );
}

export default Home;
