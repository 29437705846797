import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className="bg-primary text-white p-4">
      <div className="flex justify-between items-center max-w-4xl mx-auto">
        <h1 className="text-lg font-bold">Zialectics</h1>
        <button
          className="block md:hidden focus:outline-none focus:ring-2 focus:ring-secondary"
          onClick={() => setMenuOpen(!menuOpen)}
          aria-label="Toggle navigation menu"
        >
          ☰
        </button>
        <nav
          className={`${
            menuOpen ? 'block' : 'hidden'
          } md:flex md:space-x-4 transition-all duration-300 ease-in-out`}
        >
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? 'text-accent font-bold' : 'hover:text-secondary'
            }
            aria-label="Navigate to Home"
          >
            Home
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive ? 'text-accent font-bold' : 'hover:text-secondary'
            }
            aria-label="Navigate to About"
          >
            About
          </NavLink>
          <NavLink
            to="/services"
            className={({ isActive }) =>
              isActive ? 'text-accent font-bold' : 'hover:text-secondary'
            }
            aria-label="Navigate to Services"
          >
            Services
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              isActive ? 'text-accent font-bold' : 'hover:text-secondary'
            }
            aria-label="Navigate to Contact"
          >
            Contact
          </NavLink>
        </nav>
      </div>
    </header>
  );
};

export default Header;
