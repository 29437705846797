import React from 'react';
import Tenets from './Tenets';
import Header from './Header';
import Container from './Container';
import SectionHeader from './SectionHeader';

const About = () => {
  return (
    <>
      <Header />
      <Container>
        <SectionHeader>Our Vision</SectionHeader>
        <div className="max-w-4xl mx-auto prose">
          <p>
            To bridge the gap between business and technology, enabling our clients to achieve sustainable growth through tailored digital solutions.

            Zialectics LLC specializes in creating technology solutions that align with real business needs, fostering trust, scalability, and efficiency. Our team thrives on delivering outcomes that truly make a difference.
          </p>

          {/* Tenets Section */}
          <Tenets />

          {/* What Sets Us Apart Section */}
          <h2 className="text-2xl font-semibold mt-6 text-center">What Sets Us Apart</h2>
          <ul>
            <li>Experience across diverse industries, including aviation, marketing, advertising technology, and data science.</li>
            <li>Commitment to long-term partnerships built on transparency and mutual success.</li>
            <li>Focus on solving the right problems with scalable, cost-effective solutions.</li>
          </ul>
        </div>
      </Container>
    </>
  );
};

export default About;
